<template>
  <div class="">
    <div class="video-container">
      <video v-if="videoUrl" :src="videoUrl" controls="controls" style="width:450px;height:300px;">
        您的浏览器不支持视频播放
      </video>
      <el-button v-if="videoUrl" class="delete" type="danger" size="mini" icon="el-icon-delete" circle
        @click.stop="handleRemove()"></el-button>
    </div>
    <el-upload v-if="!videoUrl" :action="baseURL" ref="upload" list-type="picture-card" :multiple="false" :limit="1"
      :show-file-list="false" accept="video/*" :auto-upload="false" :on-change="onChange">
      <i class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <el-progress :percentage="progressPercent" :stroke-width="5" v-if="isShowProgress" style="width:55%"></el-progress>

  </div>
</template>

<script>

import TcVod from 'vod-js-sdk-v6'
import { URL_MINOR, baseURL } from '@/config'
export default {
  name: 'uploadVideo',
  props: {
    filesSize: { // 文件大小限制M
      type: Number,
      default: 50
    },
    filesSizeUnit: {
      type: String,
      default: 'MB', // M、KB
    },
    file: { // 默认回显列表
      type: String,
    },
    filesType: { // 支持类型
      type: Array,
      default: () => ['video/mp4']
      ,
    },
    styleType: {
      type: String,
      default: 'button', // 样式类型 1.button是点击上传;2.add是+
    },
  },
  data() {
    return {
      baseURL,
      /* 文件上传 */
      videoUrl: '',
      videoHeigh: '',
      videoWidth: '',
      duration: '',
      isShowProgress: false,
      progressPercent: 0
    }
  },
  methods: {
    setVideoUrl(url) {
      this.videoUrl = url
    },
    onChange(file, fileList) {
      if (file.status === 'ready') {
        this.isShowProgress = true
        this.progressPercent = 0
        const tcVod = new TcVod({
          getSignature: this.getVodSignature // 前文中所述的获取上传签名的函数
        })
        const uploader = tcVod.upload({
          mediaFile: file.raw, // 媒体文件（视频或音频或图片），类型为 File
        })
        uploader.on('media_progress', (info) => {
          if (info.percent != 1) {
            this.progressPercent = parseInt(info.percent * 100);
          }
        })
        uploader.done().then((doneResult) => {
          this.progressPercent = parseInt(1 * 100);
          const video = document.createElement('video')
          video.src = window.URL.createObjectURL(file.raw)
          video.ondurationchange = e => {
            this.videoFile = file.raw
            this.videoHeight = e.target.videoHeight || e.path[0].videoHeight
            this.videoWidth = e.target.videoWidth || e.path[0].videoWidth
            this.videoUrl = doneResult.video.url
            this.duration = parseInt(video.duration)
            this.$emit('update:file', {
              videoFile: file.raw,
              videoHeight: this.videoHeight,
              videoWidth: this.videoWidth,
              videoUrl: this.videoUrl,
              duration: this.duration,
              fileId: doneResult.fileId,
              video: this.videoUrl
            })
            this.loading = false
          }
        }).catch((err) => {
          console.error(err)
          this.$message.error(file.name + '：' + (file.message || file.msg || '上传失败'))
          // deal with error
        }).finally(() => {
          setTimeout(() => this.isShowProgress = false, 1000);
        })
      }
    },

    /**
       * 文件上传后
       */
    uploadSuccessOne(res, rawFile) {
      this.closeLoading()
    },
    handleRemove() {
      this.videoUrl = ''
      this.$emit('update:file', {})
      this.isShowProgress = false
    },
    getVodSignature() {
      return this.axios.get(URL_MINOR.commonFile.getVodSignature).then(function (response) {
        return response.data
      }).catch(() => this.isShowProgress = false)
    }
  },
  watch: {
    file(newVal) {
      this.setVideoUrl(newVal)
    },
  },
  created() {
    this.setVideoUrl(this.file)
  }
}
</script>

<style lang="less" scoped>
.video-container {
  position: relative;

  .delete {
    position: absolute;
    margin-left: -30px;
    top: 25px;
    z-index: 2;
  }
}
</style>
