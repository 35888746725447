<template>
  <div class="">
    <div class="video-container" v-loading="loading">
      <video v-if="videoUrl && !quillVideo" :src="videoUrl" controls="controls" style="width:450px;height:300px;">
        您的浏览器不支持视频播放
      </video>
      <el-button v-if="videoUrl && !quillVideo" class="delete" type="danger" size="mini" icon="el-icon-delete" circle @click.stop="handleRemove()"></el-button>
    </div>
    <el-upload
      v-if="!videoUrl || quillVideo"
      ref="upload"
      :action="actionVideo"
      :headers="headers"
      :data="params"
      list-type="picture"
      :disabled="readOnly"
      :multiple="false"
      :limit="1"
	  accept="video/*"
      :http-request="httpRequest"
      :show-file-list="false"
      :on-change="onChange"
      :before-upload="function(){return uploadBeforeOne.apply(this,[...arguments,'files'])}"
      :on-success="function(){uploadSuccessOne.apply(this,[...arguments,'files'])}"
      :on-error="function(){uploadError.apply(this,[...arguments,'files'])}">
      <template>
        <el-button v-if="styleType === 'button'" v-show="!quillVideo" size="small" type="primary">点击上传</el-button>
        <i v-else-if="styleType === 'add'" class="el-icon-plus avatar-uploader-icon"></i>
        <div slot="tip" class="el-upload__tip" v-if="showHintText">只能上传mp4文件，文件大小限制在{{filesSize}}{{filesSizeUnit}}以内。
        </div>
      </template>
    </el-upload>
    <el-progress :percentage="progressPercent" :stroke-width="5" v-if="isShowprogress" style="width:55%"></el-progress>

  </div>
</template>

<script>

import { uploadImageMixins } from './uploadImage'

export default {
  name: 'uploadVideo',
  mixins: [uploadImageMixins],
  props: {
    filesSize: { // 文件大小限制M
      type: Number,
      default: 50
    },
    filesSizeUnit: {
      type: String,
      default: 'MB', // M、KB
    },
    file: { // 默认回显列表
      type: String,
    },
    filesType: { // 支持类型
      type: Array,
      default: () => ['video/mp4']
      ,
    },
    styleType: {
      type: String,
      default: 'button', // 样式类型 1.button是点击上传;2.add是+
    },
	quillVideo: { // 是否为富文本调用 
		type: Boolean,
		default: false
	}
  },
  data () {
    return {
      /* 文件上传 */
      videoUrl: '',
      // file: '',
      videoHeigh: '',
      videoWidth: '',
      duration: '',
      isShowprogress: false,
    }
  },
  methods: {
    setVideoUrl (url) {
      this.videoUrl = url
    },
    onChange (file, fileList) {
      console.log('文件状态', file)
      if (file.status === 'ready') {
        this.isShowprogress = true
        this.progressPercent = 0
        /* const interval = setInterval(() =>{
              if(this.progressPercent >=99){
                clearInterval(interval)
                return
              }
              this.progressPercent +=1
            },200) */
      }
    },

    async uploadBeforeOne (file, origin) {
      if (!this.uploadBefore(...arguments)) {
        this.isShowprogress = false
        return false
      }
      this.loading = true
      this.params.extend = file.type.split('/')[1]
      this.params.length = file.size
      this.params.md5 = await this.fileSparkMD5(file)
      console.log('计算MD5', this.params)
      return true
    },

    /**
       * 文件上传后
       */
    uploadSuccessOne (res, rawFile) {
      this.closeLoading()
      if (res.code !== 0) {
        this.uploadError(rawFile)
        return false
      }
      let data = res.data
      if (data.url?.indexOf('http:') != -1)  data.url = data.url.replace('http:', 'https:')
      if (data.url?.indexOf('https:') != -1) data.url = data.url.replace('https:', 'https:')
      const video = document.createElement('video')
      video.src = window.URL.createObjectURL(rawFile)
      video.ondurationchange = e => {
        this.videoFile = rawFile
        this.videoHeight = e.target.videoHeight || e.path[0].videoHeight
        this.videoWidth = e.target.videoWidth || e.path[0].videoWidth
        this.videoUrl = data.url
        this.duration = parseInt(video.duration)
      }
      if (res.data.tencentCosForm) {
        this.uploadTencentCos(res.data.tencentCosForm, rawFile).then(res => {
          this.upLoadToOssWithSign(this.actionVideo, res => {
            if (res.data.url) {
              let url = res.data.url
              if (url?.indexOf('http:') != -1) this.videoUrl = data.url.replace('http:', 'https:')
              if (url?.indexOf('https:') != -1) this.videoUrl = data.url.replace('https:', 'https:')
            } else {
              this.progressPercent = 0
              this.$refs.upload.clearFiles()
            }
          })
        })
      } else {
        this.progressPercent = 100
      }
    },

    handleRemove () {
      this.videoUrl = ''
      this.isShowprogress = false
    },
    httpRequest (file) {
      this.upLoadToOssWithSign(this.actionVideo, res => {
        console.log('this.file', file)
        this.uploadSuccessOne(res, file.file)
        console.log('文件上传后', file)
      })
    },

  },
  watch: {
    file (newVal) {
      this.setVideoUrl(newVal)
    },
    videoUrl (newVal) {
      console.log(newVal)
      const result = { video: newVal }
      if (this.videoFile) result.videoFile = this.videoFile
      if (this.videoHeight) result.videoHeight = this.videoHeight
      if (this.videoWidth) result.videoWidth = this.videoWidth
      if (this.duration) result.duration = this.duration
      if (this.videoHeight && result.video) this.$emit('update:file', result)
    },
  },
  created () {
    this.setVideoUrl(this.file)
  }
}
</script>

<style lang="less" scoped>

  .video-container {
    position: relative;
    .delete {
      position: absolute;
      margin-left: -30px;
      top: 25px;
      z-index: 2;
    }
  }

</style>
